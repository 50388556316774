<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Brand Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter Brand Name here..." maxlength="100" v-if="brand" v-model="brand.name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Print Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="brand" v-model="brand.p_name">
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
import FabButton from '@/components/core/FabButton.vue'
import {userService} from '@/store/auth-header.js'
export default {
  name: 'BrandForm',
  data () {
    return {
      brand: JSON.parse('{"id":0,"status":"","name":""}')
    }
  },
  props: {
    mybrand: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"","name":""}')
    }
  },
  beforeMount () {
    this.brand = this.mybrand; // save props data to itself's data and deal with tms
  },
  component: {
    FabButton
  },
  created () {
    this.$data.brand = JSON.parse('{"id":0,"status":"","name":""}')
  },
  mounted () {

  },
  methods: {
    closeWindow() {
      this.$emit('brand_window_closed');
    },
    saveDocument(){
      let self = this;

      if(self.$data.brand.name.trim().length < 1){
        alert("Invalid Name");
        return
      }

      const requestOptions = {
        method:  (self.$data.brand.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.brand)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/brand`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.ok) {
          swal ( { title: "Success!" ,  text: data.msg, type:  "success"} );
          self.brand = JSON.parse('{"id":0,"status":"","name":""}');
          self.$emit('brand_saved');

        } else {
          swal ( { title: "Oh noes!" ,  text: data.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>

</style>
